import React, { useEffect, useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { orange, pink } from "@mui/material/colors";
import { Folders } from "./components";
import DwvComponent from "./DwvComponent";
import JSZip, { file } from "jszip";
import { extractFilesFromZip } from "./helper";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
} from "@mui/material";
import "./App.css";
import WarningIcon from "@mui/icons-material/Warning";

export default function App() {
  const [files, setFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [unzipping, setUnzipping] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0); // New state for download progress
  const [open, setOpen] = useState(false);

  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const isMobile = useMediaQuery("(max-width:600px)");

  const theme = createTheme({
    typography: {
      useNextVariants: true,
    },
    palette: {
      primary: {
        main: orange[500],
      },
      secondary: {
        main: pink[500],
      },
      mode: prefersDarkMode ? "dark" : "light",
    },
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const fetchFiles = async () => {
    setLoading(true);
    setDownloadProgress(0); 

    console.log("ppppppp")

    try {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const fileUrl = urlParams.get("url");

      if (fileUrl) {
        const response = await fetch(fileUrl);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        console.log(fileUrl)

        const contentLength = response.headers.get("Content-Length");
        if (!contentLength) {
          throw new Error("Content-Length header is missing.");
        }

        const totalBytes = parseInt(contentLength, 10);
        let downloadedBytes = 0;

        const reader = response.body.getReader();
        const stream = new ReadableStream({
          async start(controller) {
            while (true) {
              const { done, value } = await reader.read();
              if (done) {
                controller.close();
                break;
              }
              downloadedBytes += value.length;
              const progress = Math.floor((downloadedBytes / totalBytes) * 100);
              setDownloadProgress(progress); 

              controller.enqueue(value);
            }
          },
        });

        const blob = await new Response(stream).blob();

        setLoading(false);
        setUnzipping(true);

        const zip = new JSZip();
        const zipContents = await zip.loadAsync(blob);
        const extractedFiles = await extractFilesFromZip(zipContents);
        setFiles(extractedFiles);
        setSelectedFile(extractedFiles[0]);

        setUnzipping(false);
      } else {
        window.location.href = "https://onehealthassist.com/";
      }
    } catch (error) {
      console.error("Error loading file:", error);
      alert("Error loading file:", error.response);
      window.location.href = "https://onehealthassist.com/";
    } finally {
      setLoading(false);
      setUnzipping(false);
    }
  };

  useEffect(() => {
    fetchFiles();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          height: "100vh",
        }}
      >
        {loading ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <div className="loading">
              <svg
                className="responsive-svg"
                viewBox="0 0 16 12"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="xMidYMid meet"
              >
                <polyline
                  id="back"
                  points="1 6 4 6 6 11 10 1 12 6 15 6"
                  fill="none"
                ></polyline>
                <polyline
                  id="front"
                  points="1 6 4 6 6 11 10 1 12 6 15 6"
                  fill="none"
                ></polyline>
              </svg>
            </div>

            <Typography
              variant="h5"
              sx={{ color: theme.palette.text.primary, marginTop: 2 }}
            >
              Downloading... {downloadProgress}% {/* Display download progress */}
            </Typography>
            <Typography variant="h5" sx={{ color: theme.palette.text.primary }}>
              It will take time, please wait...
            </Typography>
          </Box>
        ) : unzipping ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="unzipping"></div>
            </Box>
            <Typography variant="h5" sx={{ color: theme.palette.text.primary }}>
              Unzipping file, please wait...
            </Typography>
          </Box>
        ) : (
          <>
            <Box
              sx={{
                flex: isMobile ? "0 0 35%" : 1,
                overflowY: "auto",
                maxWidth: isMobile ? "100%" : "320px",
                padding: isMobile ? "10px" : 0,
                order: isMobile ? 2 : 1,
              }}
            >
              <Folders
                setSelectedFile={setSelectedFile}
                data={files}
                selectedFile={selectedFile}
              />
            </Box>
            <Box
              sx={{
                flex: isMobile ? "0 0 65%" : 2,
                overflow: "auto",
                padding: "10px",
                order: isMobile ? 1 : 2,
              }}
            >
              {selectedFile && <DwvComponent file={selectedFile} />}
            </Box>
          </>
        )}
        <Box
          sx={{
            position: "absolute",
            padding: 2,
            backgroundColor: " #ff7700",
            borderRadius: "50%",
            bottom: 20,
            right: 20,
          }}
          onClick={handleClickOpen}
        >
          <WarningIcon />
        </Box>

        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Warning</DialogTitle>
          <DialogContent>
            <Typography>
              This viewer is not intended for medical purposes. Use it at your
              own risk.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </ThemeProvider>
  );
}
