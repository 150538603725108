import PlayArrow from "@mui/icons-material/PlayArrow";
import Pause from "@mui/icons-material/Pause";
import ContrastIcon from "@mui/icons-material/Contrast";
import StraightenIcon from "@mui/icons-material/Straighten";
import ZoomIn from "@mui/icons-material/ZoomIn";
import { Box, Typography } from "@mui/material";

export const isDicomFile = async (blob) => {
  const arrayBuffer = await blob.slice(0, 132).arrayBuffer();
  const bytes = new Uint8Array(arrayBuffer);
  return (
    bytes[128] === 0x44 &&
    bytes[129] === 0x49 &&
    bytes[130] === 0x43 &&
    bytes[131] === 0x4d
  );
};

export const extractFilesFromZip = async (zip) => {
  const validExtensions = ["dcm", "dicom", "DCM", ""];

  // Helper function to check if a file has a valid extension
  const hasValidExtension = (filename) => {
    if (filename.toUpperCase() === "DICOMDIR") false;

    const ext = filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);
    return validExtensions.includes(`${ext.toLowerCase()}`);
  };

  // Extract and filter files with valid extensions
  const filteredFiles = await Promise.all(
    Object.keys(zip.files).map(async (relativePath) => {
      const zipEntry = zip.files[relativePath];
      if (!zipEntry.dir && hasValidExtension(relativePath)) {
        const fileBlob = await zipEntry.async("blob");
        // Extract only the file name from the relative path
        const fileName = relativePath.split("/").pop();
        return {
          id: null, // Placeholder for ID, to be assigned later
          name: fileName,
          blob: fileBlob,
        };
      }
      return null;
    })
  );

  // Remove null entries (non-matching files)
  const filesWithId = filteredFiles
    .filter((file) => file !== null)
    .map((file, index) => ({
      ...file,
      id: index + 1, // Assign IDs starting from 1
    }));

  return filesWithId;
};

/**
 * Converts an array of Blob objects to a FileList object.
 * @param {Blob[]} blobs - Array of Blob objects to convert.
 * @returns {FileList} - The resulting FileList object.
 */
export const blobsToFileList = (blobs) => {
  // Create a new DataTransfer object
  const dataTransfer = new DataTransfer();

  // Add each blob as a File to the DataTransfer object
  blobs.forEach((blob, index) => {
    // Create a new File object for each Blob
    const file = new File([blob], `file${index}`, { type: blob.type });
    dataTransfer.items.add(file);
  });

  // Return the FileList from the DataTransfer object
  return dataTransfer.files;
};

/**
 * Get the icon of a tool.
 *
 * @param {string} tool The tool name.
 * @returns {Icon} The associated icon.
 */
export const getToolIcon = (tool) => {
  switch (tool.name) {
    case "Scroll":
      return (
        <Box display="flex" alignItems="center">
          {tool.icon === "Play" && <PlayArrow />}
          {tool.icon === "Pause" && <Pause />}
          <Typography variant="body1" sx={{ marginLeft: 1 }}>
            {tool.icon === "Play" ? "Play" : "Pause"}
          </Typography>
        </Box>
      );

    case "ZoomAndPan":
      return (
        <Box display="flex" alignItems="center">
          <ZoomIn />
          <Typography variant="body1" sx={{ marginLeft: 1 }}>
            Zoom
          </Typography>
        </Box>
      );

    case "WindowLevel":
      return (
        <Box display="flex" alignItems="center">
          <ContrastIcon />
          <Typography variant="body1" sx={{ marginLeft: 1 }}>
            Contrast
          </Typography>
        </Box>
      );

    case "Draw":
      return (
        <Box display="flex" alignItems="center">
          <StraightenIcon />
          <Typography variant="body1" sx={{ marginLeft: 1 }}>
            Scale
          </Typography>
        </Box>
      );

    default:
      return null;
  }
};
